import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

// Components
import NewsletterForm from "./newsletterForm"


const Form = ({ heading, subheading }) => {
  return (
      <>
      {heading && 
          <h2 id="subscribe" className="text-2xl sm:text-5xl text-center font-medium mb-8">
            {heading}
          </h2>
      }
      {subheading && 
        <h3 className="text-base sm:text-xl text-center mx-auto max-w-[700px]">
          {subheading}
        </h3>
      }
      <div id="newsletter" className="flex mt-20 mb-32 flex-row justify-center max-h-[319.73px]">
        <div className="
        hidden 2xl:flex 
        flex-[0_1_452px]
        -mr-20
        -z-10
        ">
          <StaticImage src="../../images/cta-image-left.png" alt=""/>
        </div>
        <div className="flex md:flex-[0_0_675px] items-center justify-center rounded-lg bg-white">
          <NewsletterForm/>
        </div>
        <div 
        className="
        hidden 2xl:flex 
        flex-[0_1_452px]
        -ml-10
        -z-10">
          <StaticImage 
          src="../../images/cta-image-right.png" 
          alt=""         
        />
        </div>
      </div>
    </>
  )
}

export default Form
