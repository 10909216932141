import React, { useState } from "react"
import { Formik } from "formik"
import addToMailchimp from "gatsby-plugin-mailchimp"


const NewsletterForm = () => {
    const [success, setSuccess] = useState(false);
    return (
        <Formik
            initialValues={{ email: "", confirm: "", name: "" }}
            validate={values => {
            const errors = {}
            if (!values.name) {
                errors.name = "Required"
            }
            if (!values.email) {
                errors.email = "Required"
            } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
                errors.email = "Invalid email address"
            }
            if (values.email !== values.confirm) {
                errors.confirm = "Must match"
            }
            return errors
            }}
            onSubmit={(values, { setSubmitting }) => {
            addToMailchimp(values.email, {
                FNAME: values.name
            }) // listFields are optional if you are only capturing the email address.
                .then(data => {
                    setSubmitting(false)
                    if (data.result === "error") {
                        console.log(data.result, 'failed')
                    } else {
                        setSuccess(true)
                    } 
                })
            }}
            >
            {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
            }) => (
            <form 
                className="flex flex-initial flex-col gap-4 items-center p-8" 
                onSubmit={handleSubmit}>
                <div className="relative">
                    <input
                        name="name"
                        type="text"
                        placeholder="Name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                        className="
                            mt-1
                            block
                            min-w-[240px]
                            sm:min-w-[441px]
                            rounded-sm
                            bg-neutral-light
                            text-neutral-dark
                            placeholder:text-neutral-dark
                            border-none
                            text-lg
                            h-10
                            px-6"
                    />
                    <p className="absolute text-[14px] text-red">
                    {errors.name && touched.name && errors.name}
                    </p>
                </div>
                <div className="relative">
                    <input
                        name="email"
                        type="email"
                        placeholder="E-mail address"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        className="
                            mt-1
                            block
                            min-w-[240px]
                            sm:min-w-[441px]
                            rounded-sm
                            bg-neutral-light
                            text-neutral-dark
                            placeholder:text-neutral-dark
                            border-none
                            text-lg
                            h-10
                            px-6"
                    />
                    <p className="absolute text-[14px] text-red">
                    {errors.email && touched.email && errors.email}
                    </p>
                </div>
                <div className="relative">
                    <input
                        name="confirm"
                        type="email"
                        placeholder="Confirm E-mail address"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.confirm}
                        className="
                            mt-1
                            block
                            min-w-[240px]
                            sm:min-w-[441px]
                            rounded-sm
                            bg-neutral-light
                            text-neutral-dark
                            placeholder:text-neutral-dark
                            border-none
                            text-lg
                            h-10
                            px-6"
                    />
                    <p className="absolute text-[14px] text-red">
                    {errors.confirm && touched.confirm && errors.confirm}
                    </p>
                </div>
                {!isSubmitting ? (
                    success ? (
                        <div className="relative w-full mx-auto text-center">
                        <button 
                        disabled={true}
                        type="submit" 
                        className="
                        rounded-full 
                        text-lg 
                        font-medium 
                        uppercase 
                        bg-green
                        text-white
                        mt-3 
                        h-10
                        w-44
                        "         
                        >
                        Success!
                    </button>
                    <p className={`absolute bottom-[-26px] left-16 sm:left-[138px] text-[14px] text-medium text-neutral-dark ${success ? 'block' : 'hidden'}`}>
                        Thank you for subscribing.
                    </p>
                    </div>
                    ) : (
                        <button 
                        type="submit" 
                        className="
                        rounded-full 
                        text-lg 
                        font-medium 
                        uppercase 
                        bg-blue-darker 
                        hover:bg-blue-darkest
                        text-white
                        transition-colors
                        mt-3 
                        h-10
                        w-44
                        "         
                        >
                        Subscribe
                    </button>
                    )
                ) : (
                    <button 
                        type="submit" 
                        disabled={true}
                        className="
                        rounded-full 
                        text-lg 
                        font-medium 
                        uppercase 
                        bg-blue-darker 
                        text-white
                        mt-3 
                        h-10
                        w-44
                        "         
                        >
                        Sending...
                    </button>
                )}
            </form>
            )}
        </Formik>
    )
}

export default NewsletterForm

